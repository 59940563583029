import {toRubles} from "@/utils/money";

export const SALES_FIELDS = [
    {
        key: 'payment_amount',
        label: 'Сумма поступления',
        formatter: (value) => toRubles(value)
    },
    {
        key: 'cost_trip_cost',
        label: 'Сумма за билеты',
        formatter: (value) => toRubles(value)
    },
    {
        key: 'cost_trip_cost',
        label: 'Сумма сумма за билеты',
        formatter: (value) => toRubles(value)
    },
    {
        key: 'cost_service_fee',
        label: 'Сумма сервисного сбора',
        formatter: (value) => toRubles(value)
    },
    {
        key: 'cost_cash_box_fee',
        label: 'Сумма кассового сбора',
        formatter: (value) => toRubles(value)
    },
    {
        key: 'payment_system_commission_percent',
        label: 'Комиссия платежной системы (0%)',
        formatter: () => 0
    },
    {
        key: 'orders_count',
        label: 'Всего заказов'
    },
    {
        key: 'tickets_count',
        label: 'Всего мест'
    },
    {
        key: 'tickets_count',
        label: 'Всего мест'
    }
]

export const REFUNDS_FIELDS = [
    {
        key: 'payment_amount',
        label: 'Сумма оплат',
        formatter: (value) => toRubles(value)
    },
    {
        key: 'cost_trip_cost',
        label: 'Сумма оплат за билеты',
        formatter: (value) => toRubles(value)
    },
    {
        key: 'baggage_cost',
        label: 'Сумма оплат за багажные места',
        formatter: () => toRubles(0)
    },
    {
        key: 'cost_service_fee',
        label: 'Сумма оплат сервисного сбора',
        formatter: (value) => toRubles(value)
    },
    {
        key: 'cost_cash_box_fee',
        label: 'Сумма оплат кассового сбора',
        formatter: (value) => toRubles(value)
    },
    {
        key: 'refund_amount',
        label: 'Сумма возврата',
        formatter: (value) => toRubles(value)
    },
    {
        key: 'refund_service_fee',
        label: 'Сумма возврата сервисного сбора',
        formatter: (value) => toRubles(value)
    },
    {
        key: 'refund_cash_box_fee',
        label: 'Сумма возврата кассового сбора',
        formatter: (value) => toRubles(value)
    },
    {
        key: 'deducted_from_tickets',
        label: 'Удержано с билетов',
        formatter: (value) => toRubles(value)
    },
    {
        key: 'deducted_service_fee',
        label: 'Удержано сервисного сбора',
        formatter: (value) => toRubles(value)
    },
    {
        key: 'deducted_cash_box_fee',
        label: 'Удержано кассового сбора',
        formatter: (value) => toRubles(value)
    },
    {
        key: 'orders_count',
        label: 'Всего заказов'
    },
    {
        key: 'tickets_count',
        label: 'Всего мест'
    },
    {
        key: 'tickets_count_rk',
        label: 'Всего мест (РК)',
        formatter: () => 0
    }
]